import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const statisticsStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 일별 부스 통계
        boothDaily: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetBoothDaily/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate+'&adtype='+params.adtype
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 클릭 일별 통계
        clickDaily: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetClickDaily/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate+'&adtype='+params.adtype
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의하기 발송 내역
        getContacts: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetContacts/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의하기 일별 총계
        contactsDaily: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetContactsDaily/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 클릭 일별 통계 엑셀
        clickDailyExcel: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetClickDailyExcel/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate,
                { responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의 일별 통계 엑셀
        contactsDailyExcel: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetContactsDailyExcel/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate,
                { responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 문의 리스트
        contactsExcel: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetContactsExcel/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate,
                { responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 부스 통계 엑셀
        boothDailyExcel: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Statistics/GetBoothDailyExcel/'+params.siteid+'?sdate='+params.sdate+'&edate='+params.edate,
                { responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 마토모 통계 엑셀
        matomoExcel: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Statistics/GetMatomoExcel/', params,
                { responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default statisticsStore