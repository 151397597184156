<template>
  <div id="app" >
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
 
  }
}

</script>

<style>
#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
</style>
