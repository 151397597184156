import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

import "@/assets/css/style.css";
const app = createApp(App);
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});

app.use(router).use(store).mount('#app')
