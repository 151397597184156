import { createStore } from 'vuex';
import axios from 'axios'
import VueCookies from 'vue-cookies';

import statisticsStore from '@/store/modules/statisticsStore.js';

export default new createStore({
    state: {
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },

        setUser(state, data){
            state.user.data = data;
            VueCookies.set('onmice_stat_accessToken', data.accessToken);
            VueCookies.set('onmice_stat_refreshToken', data.refreshToken);
            VueCookies.set('onmice_stat_userUqId', data.userUqId);
            VueCookies.set('onmice_stat_email', data.email);
            VueCookies.set('onmice_stat_siteid', data.siteid);
            VueCookies.set('onmice_stat_matomoid', data.matomoid);
            VueCookies.set('onmice_stat_startdate', data.startdate);
            VueCookies.set('onmice_stat_enddate', data.enddate);
        },

        logoutUser(state){
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('onmice_stat_accessToken');
            VueCookies.remove('onmice_stat_refreshToken');
            VueCookies.remove('onmice_stat_userUqId');
            VueCookies.remove('onmice_stat_email');
            VueCookies.remove('onmice_stat_siteid');
            VueCookies.remove('onmice_stat_matomoid');
            VueCookies.remove('onmice_stat_startdate');
            VueCookies.remove('onmice_stat_enddate');
        },
    },
    getters: {

        user(state){
            return state.user;
        },
    },
    actions: {},
    modules: {
        statisticsStore: statisticsStore,
    },
});
