import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from '../lib/supabaseClient'
import VueJwtDecode from 'vue-jwt-decode';
import VueCookies from 'vue-cookies';

import Home from '../views/index.vue';
import store from '../store';

const routes = [
    //dashboard
    { 
        path: '/', 
        name: 'Home', 
        component: Home,
        meta: {
            authRequired: true,
            title: '대시보드',
        },
    },
    {//로그인
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login.vue'),
        meta: {
            title: '로그인',
        },
    },
    {// 문의게시판
        path: '/contact',
        name: 'contact',
        component: () => import('../views/board/list.vue'),
        meta: {
            title: '문의게시판',
        },
    },
    {// 문의게시판 상세보기
        path: '/contactView',
        name: 'contactView',
        component: () => import('../views/board/view.vue'),
        meta: {
            title: '문의게시판 상세보기',
        },
    },
    {// 점검중 페이지
        path: '/check',
        name: 'check',
        component: () => import('../views/check/index.vue'),
        meta: {
            title: '점검중',
        },
    },

];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    var token = VueCookies.get('onmice_stat_accessToken');
    var isVerified = false;

    document.title = to.meta.title + " | ONMICE Analytics";
    
    if(token !== null){
        let decoded = VueJwtDecode.decode(token);
        //console.log(decoded);
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        // accessToken 유효한지 검사
        if(expireDate > today){
            isVerified = true;
        }
    }

    if(to.path.includes("login")){
        return next();
    }else{
        if (store.state.user.loggedIn){
            return next();
        }else{
            if (token !== null){
                //accessToken이 있을 경우 진행
                var userInfo = {
                    "accessToken": VueCookies.get('onmice_stat_accessToken'),
                    "refreshToken": VueCookies.get('onmice_stat_refreshToken'),
                    "userUqId": VueCookies.get('onmice_stat_userUqId'),
                    "email": VueCookies.get('onmice_stat_email'),
                    "siteid": VueCookies.get('onmice_stat_siteid'),
                    "matomoid": VueCookies.get('onmice_stat_matomoid'),
                    "startdate": VueCookies.get('onmice_stat_startdate'),
                    "enddate": VueCookies.get('onmice_stat_enddate'),
                };

                //console.log(userInfo);
        
                if(isVerified){

                    // accessToken이 유효할 경우
                    store.commit('setLoggedIn', true);
                    store.commit('setUser', userInfo);

                    return next();

                }else{

                    // accessToken이 만료되었을 경우 - 로그인 페이지로
                    next("/login");
                }
                
            }else{
                if(!isVerified && token !== null){
                    // accessToken이 종료되었을 경우 로그아웃 진행
                    store.commit('logoutUser')

                    const { error } = supabase.auth.signOut()
                }

                const authRequired = to.matched.some((route) => route.meta.authRequired)

                if(!authRequired){
                    return next();
                }else{
                    next("/login");
                }
            }
        }
    }
    //next(true);
});

export default router;
