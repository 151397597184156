<template>
    <div>
        <!-- 헤더 -->
        <Header />
        <!-- 헤더 end -->
         
        <div class="home">
            <div class="pg container ">
                <div ref="pdfarea" class="statisticsBox">
                    <div ref="topBox" class="title topBox">
                        <h2>통계 기간</h2>
                        <div class="df dateBox">
                            <flat-pickr v-model="rangeDate" :config="{ mode: 'range' }" class="form-control flatpickr active"></flat-pickr>
                            <button @click="changeDates();" class="button is-link datebtn">조회</button>
                        </div>
                        <button @click="exportToPDF" class="button  pdfBtn">PDF 다운로드</button>
                    </div>
                    <!--
                    <div class="tile is-ancestor">
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <p class="title">통계항목1</p>
                                <div class="content" style="height: 200px;">
                                </div>
                            </article>
                        </div>
                    </div>
                    -->
                    <div ref="topStatRow" class="tile is-ancestor">
                        <div ref="topStatRowLeft" class="tile is-vertical is-8">
                            <div class="tile">

                                <div class="tile is-parent is-vertical top1">

                                    <article class="tile is-child border">
                                        <p class="title">컨퍼런스 진행 상태</p>
                                        <div class="content">
                                            <!--
                                            <div class="eventDate">컨퍼런스 시작일 {{ startdate }} ~ 현재</div>
                                            -->
                                            <div class="tile is-parent pd-0">
                                                <!--
                                                <div class="tile is-child border eventTime"><b>{{ eventTime.month }}</b><br />Months</div>
                                                -->
                                                <div class="tile is-child border eventTime"><b>{{ eventTime.day }}</b><br />Days</div>
                                                <div class="tile is-child border eventTime"><b>{{ eventTime.hour }}</b><br />Hours</div>
                                                <div class="tile is-child border eventTime"><b>{{ eventTime.minute }}</b><br />Minutes</div>
                                            </div>
                                        </div>
                                    </article>

                                    <!-- <article class="tile is-child border">
                                        <p class="title">트래픽 사용량</p>
                                        <div class="content">
                                            <div class="tile is-child border trafficBox">
                                                <div>
                                                    <p>방문자수</p>
                                                    <b>42,897</b>
                                                </div>
                                                <div>
                                                    <p>방문횟수</p>
                                                    <b>0</b>
                                                </div>
                                                <div>
                                                    <p>구방문자수</p>
                                                    <b>0</b>
                                                </div>
                                            </div>
                                        </div>
                                    </article> -->

                                    <article class="tile is-child border">
                                        <p class="title">부스 방문 현황</p>
                                        <div class="content">
                                            <div class="tile is-parent is-vertical pd-0">
                                                <div class="tile is-child boothVisits">
                                                    <div class="iconBox">
                                                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 18V6L8 0L16 6V18H10V11H6V18H0Z"
                                                                fill="#86DC76" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <p>부스 총 방문수</p>
                                                        <b>{{ boothVisits.visits }}</b>
                                                    </div>
                                                </div>
                                                <div class="tile is-child boothVisits">
                                                    <div class="iconBox">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M7.5 14.5L14.5 10L7.5 5.5V14.5ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
                                                                fill="#6E9CE7" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <p>부스 총 동영상 시청수</p>
                                                        <!--
                                                            <b>{{ boothVisits.video }}</b>
                                                        -->
                                                        <b>{{ clickStats.videoplay }}</b>
                                                    </div>
                                                </div>
                                                <div class="tile is-child boothVisits">
                                                    <div class="iconBox">
                                                       <svg width="14" height="20" viewBox="0 0 14 20" fill="none"
                                                           xmlns="http://www.w3.org/2000/svg">
                                                           <path
                                                               d="M7.775 20L4.15 12.2L0 18V0L14 11H6.9L10.5 18.725L7.775 20Z"
                                                               fill="#E28478" />
                                                       </svg>
                                                    </div>
                                                    <div>
                                                        <p>부스 총 배너 클릭수</p>
                                                        <!--
                                                        <b>{{ boothVisits.image }}</b>
                                                        -->
                                                        <b>{{ clickStats.image }}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                                <div class="tile is-parent is-vertical top2">

                                    <article class="tile is-child border top2child">
                                        <p ref="top2title1" class="title">방문자 통계</p>
                                        <div class="content" >
                                            <div class="tile is-parent pd-0">
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
            
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>사이트 총 방문자</p>
                                                            <b>{{ visitorStats.totalVisitor }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>사이트 순 방문자</p>
                                                            <b>{{ visitorStats.uniqueVisitor }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                    <article class="tile is-child border">
                                        <p ref="top2title2" class="title">문의 통계</p>
                                        <div class="content" >
                                            <div class="tile is-parent pd-0">
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>메인 문의</p>
                                                            <b>{{ contactStats.mainContact }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>부스 문의</p>
                                                            <b>{{ contactStats.boothContact }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                    <article class="tile is-child border">
                                        <p ref="top2title3" class="title">클릭 통계</p>
                                        <div class="content" >
                                            <div class="tile is-parent pd-0">
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>배너/이미지</p>
                                                            <b>{{ clickStats.image }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>동영상</p>
                                                            <b>{{ clickStats.video }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tile is-child visitorStats">
                                                    <div class="tile is-parent is-vertical visitorStatsParent">
                                                        <div class="tile is-child visitorStatsChild">
                                                            <p>동영상 플레이</p>
                                                            <b>{{ clickStats.videoplay }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">부스 방문 통계 순위</div> <div class="rightBox"><button @click="excelDownload('dailybooth');">부스 방문 통계 다운로드</button></div></div>
                                <div class="content">
                                    <div class="content">
                                        <div class="boothRating">
                                            <div class="tile is-parent is-vertical pd-0">
            
                                                <div v-for="(item, i) in boothArray" :key="i" class="tile is-child boothItem">
                                                    <div class="tile is-parent pd-0 boothAlign">
                                                        <div class="tile is-child boothName">
                                                            {{ item.comm }}
                                                        </div>
                                                        <div class="tile is-child boothCnt">
                                                            {{ item.countnum }}
                                                        </div>
                                                        <div class="tile is-child boothRating">
                                                            <!-- <progress class="progress is-primary" :value="item.countnum" :max="maxVisit">{{item.countnum}}</progress> -->
                                                            <div class="progress-container">
                                                                <div class="progress-bar" id="progress-bar" :style="{ width: item.progress + '%'}"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div class="tile is-ancestor" ref="longgraph">
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><span class="leftBox">일별 접속자 수</span> <div class="rightBox"><button @click="excelDownload('matomoDailyVisits');">엑셀 다운로드</button></div></div>
            
                                <div class="content">
                                    <LineChart :chartData="matomoDailyVisits" :options="options" />
                                </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">일별 클릭 통계</div> <div class="rightBox"><button @click="excelDownload('dailyclick');">엑셀 다운로드</button></div></div>
                                <div class="content">
                                    <BarChart :chartData="adtypeArray" :options="options" />
                                </div>
                            </article>
                        </div>
                    </div>
                    <div class="tile is-ancestor">
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">일별 문의 통계</div> <div class="rightBox"><button @click="excelDownload('dailycontact');">일별 문의 통계 다운로드</button> <button @click="excelDownload('contact');">문의 리스트 다운로드</button></div></div>
                                <div class="content">
                                    <LineChart :chartData="contactArray" :options="options" />
                                </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">디바이스 타입별 방문 통계</div> <div class="rightBox"><button @click="excelDownload('matomoDeviceType');">엑셀 다운로드</button></div></div>
                                <div class="content">
                                    <PieChart :chartData="matomoDeviceType" />
                                </div>
                            </article>
                        </div>
                    </div>
            
                    <div class="tile is-ancestor">
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">지역별 방문 통계</div> <div class="rightBox"><button @click="excelDownload('matomoCountry');">엑셀 다운로드</button></div></div>
                                <div class="content">
                                    <BarChart :chartData="matomoCountry" :options="options" />
                                </div>
                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child border">
                                <div class="title"><div class="leftBox">운영체제별 방문 통계</div> <div class="rightBox"><button @click="excelDownload('matomoDeviceOs');">엑셀 다운로드</button></div></div>
                                <div class="content">
                                    <BarChart :chartData="matomoDeviceOs" :options="options" />
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<!-- 차트 참고 : https://vue-chart-3.netlify.app/components/hooks/example.html -->

<script>
import Header from '@/components/layout/header';
import { defineComponent } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { LineChart, BarChart, PieChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import html2pdf from 'html2pdf.js'

import $ from 'jquery';

Chart.register(...registerables);

export default {
    name: "App",
    components: {
        Header,
        flatPickr,
        LineChart,
        BarChart,
        PieChart,
    },
    data() {
        return {
            siteid: 0,
            startdate: "",
            enddate: "",
            rangeDate: "",
            matomoCountry: {},
            matomoDeviceType: {},
            matomoDeviceOs: {},
            matomoDailyVisits: {},
            sdate: "",
            edate: "",
            data : {
                labels: [],
                datasets: [{
                    label: "방문수",
                    data: [],
                }, ],
            },
            options : {
                scales: {
                    x: {
                        grid: {
                            display: false, // 세로줄(그리드 라인) 없애기
                        }
                    },
                    y: {
                        ticks: {
                            min: 0,
                            beginAtZero:true,
                            callback: function (value) {
                                if (0 === value % 1) {
                                    return value;
                                }
                            }
                        }
                    },
                },
                responsive: true,
            },
            eventTime: {
                month: 0,
                day: 0,
                hour: 0,
                minute: 0
            },
            boothVisits: {
                visits: 0,
                video: 0,
                image: 0
            },
            boothArray: [],
            maxVisit: 0,
            visitorStats: {
                uniqueVisitor: 0,
                totalVisitor: 0
            },
            matomoid: 0,
            timeInterval: "",
            contactStats: {
                mainContact: 0,
                boothContact: 0,
            },
            clickStats: {
                image: 0,
                video: 0,
                videoplay: 0
            },
            contactArray: [],
            adtypeArray: {},
        };
    },
    mounted(){

        //this.$router.push("/check");

        var userInfo = this.$store.getters['user'];
        //console.log(userInfo);
        this.siteid = Number(userInfo.data.siteid);

        var edateArr = userInfo.data.enddate.split("T");
        var sdateArr = userInfo.data.startdate.split("T");
        
        //this.enddate = userInfo.data.enddate;
        //this.startdate = userInfo.data.startdate;
        this.enddate = edateArr[0];
        this.startdate = sdateArr[0];
       
        /*
        this.enddate = userInfo.data.enddate;
        this.startdate = userInfo.data.startdate;
         */
        this.sdate = this.startdate;
        //this.startdate = "2023-08-24";
        //this.sdate = "2023-08-24";

        var today = new Date();
        if(today < new Date(this.enddate)){
            var yyyy = today.getFullYear();
            var mm = today.getMonth() + 1;
            var dd = today.getDate();
            if(mm < 10){
                mm = "0" + mm;
            }
            if(dd < 10){
                dd = "0" + dd;
            }
            this.edate = yyyy + "-" + mm + "-" + dd;
        }else{
            this.edate = this.enddate;
        }

        this.rangeDate = this.sdate + " to " + this.edate;

        this.calcTime();
        
        this.timeInterval = setInterval(()=>{
            this.calcTime();
        }, 30000);

        this.matomoid = Number(userInfo.data.matomoid);

        this.setAllCharts();
        
    },
    beforeUnmount(){
        clearInterval(this.timeInterval);
    },
    methods: {

        // 컨퍼런스 진행 상태 - 진행 시간
        calcTime(){

            var ssdate = new Date(this.startdate);
            var yyyy = ssdate.getFullYear();
            var mm = ssdate.getMonth();
            var dd = ssdate.getDate();
            
            var diffDate1 = new Date(yyyy, mm, dd);
            var diffDate2 = new Date();

            var dateGap = diffDate2.getTime() - diffDate1.getTime();
            var timeGap = new Date(0, 0, 0, 0, 0, 0, diffDate2 - diffDate1); 

            var day = Math.floor(dateGap / (1000 * 60 * 60 * 24))
            var hour = timeGap.getHours();
            var minute = timeGap.getMinutes();
            this.eventTime.day = day;
            this.eventTime.hour = hour;
            this.eventTime.minute = minute;

        },
        
        // 날짜 변경
        changeDates(){
            //console.log(this.rangeDate);
            
            var today = new Date();

            if (this.rangeDate == "") {

                var now = new Date();
                now.setHours(now.getHours() + 9);
                now = now.toISOString();
                this.sdate = this.startdate;
                if(today < new Date(this.enddate)){
                    this.edate = now;
                }else{
                    this.edate = this.enddate;
                }
                
            } else {
                if(this.rangeDate.includes("to")){
                    var range = this.rangeDate.split(" to ");
                    this.sdate = range[0];
                    this.edate = range[1];

                    if(new Date(this.sdate) < new Date(this.startdate)){
                        alert("컨퍼런스 시작일 이후의 날짜만 선택 가능합니다.");
                        return false;
                    }
                    if(new Date(this.edate) > new Date(this.enddate)){
                        alert("컨퍼런스 종료일 이전의 날짜만 선택 가능합니다.");
                        return false;
                    }
                    if(new Date(this.sdate) > today || new Date(this.edate) > today){
                        alert("오늘 날짜 이전의 날짜만 선택 가능합니다.");
                        return false;
                    }
                }else{
                    this.sdate = this.rangeDate;
                    this.edate = this.rangeDate;
                    this.rangeDate = this.sdate + " to " + this.edate;

                    if(new Date(this.sdate) < new Date(this.startdate)){
                        alert("컨퍼런스 시작일 이후의 날짜만 선택 가능합니다.");
                        return false;
                    }
                    if(new Date(this.edate) > new Date(this.enddate)){
                        alert("컨퍼런스 종료일 이전의 날짜만 선택 가능합니다.");
                        return false;
                    }
                    if(new Date(this.sdate) > today || new Date(this.edate) > today){
                        alert("오늘 날짜 이전의 날짜만 선택 가능합니다.");
                        return false;
                    }
                }
            }

            this.setAllCharts();
            
        },

        // 모든 차트 그리기
        setAllCharts(){

            var date1 = new Date(this.sdate);
            var date2 = new Date(this.edate);

            var elapsedMSec = date2.getTime() - date1.getTime(); // 172800000
            var elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
            if(elapsedDay > 8){
                this.$refs.longgraph.style.display = "block";
            }else{
                this.$refs.longgraph.style.display = "flex";
            }
            this.getBoothDaily('visit');    // 부스 방문자 수
            //this.getBoothDaily('videoplay');    // 부스 동영상 시청수
            //this.getBoothDaily('image');    // 부스 배너 클릭수
            this.getClickDaily();
            this.getContacts();
            this.getContactsDaily();
            this.getMatomoInfo();
            
        },
        
        // 일별 부스 통계
        getBoothDaily(adtype){

            var params = {
                siteid: this.siteid,
                sdate: this.sdate,
                edate: this.edate,
                adtype: adtype,
            };
            
            //console.log(params);

            this.$store.dispatch('statisticsStore/boothDaily', params)
            .then((res) => {
                //console.log(adtype);
                //console.log(res);
                if(res.success){

                    var cnt = 0;
                    if(res.data.length > 0){
                        
                        for(var i = 0; i < res.data.length; i++){
                            cnt += res.data[i].countnum;
                        }

                        if(adtype == 'visit'){
                            this.boothVisits.visits = cnt;

                            // 부스 방문 통계 순위
                            var boothArray = res.data;

                            // boothid 가 같으면 countnum을 더해준다.
                            boothArray = boothArray.reduce((acc, cur) => {
                                const found = acc.find(v => v.boothid === cur.boothid);
                                if (found) {
                                    found.countnum += cur.countnum;
                                } else {
                                    acc.push(cur);
                                }
                                return acc;
                            }, []);

                            // countnum을 기준으로 내림차순 정렬
                            boothArray.sort((a, b) => {
                                if (a.countnum < b.countnum) return 1;
                                if (a.countnum > b.countnum) return -1;

                                return 0;
                            });

                            this.boothArray = boothArray;

                            this.maxVisit = boothArray[0].countnum;
                            for(var j = 0; j < this.boothArray.length; j++){
                                var progress = this.boothArray[j].countnum / this.maxVisit * 100;
                                this.boothArray[j].progress = progress;
                            }

                        }else if(adtype == 'image'){
                            this.boothVisits.image = cnt;
                        }else if(adtype == 'videoplay'){
                            this.boothVisits.video = cnt;
                        }
                        
                    }else{
                        if(adtype == 'visit'){
                            this.boothVisits.visits = 0;
                            this.boothArray = [];
                            this.maxVisit = 0;
                        }else if(adtype == 'image'){
                            this.boothVisits.image = 0;
                        }else if(adtype == 'videoplay'){
                            this.boothVisits.video = 0;
                        }
                    }
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 클릭 일별 통계
        getClickDaily(){

            var params = {
                siteid: this.siteid,
                sdate: this.sdate,
                edate: this.edate,
                adtype: '',
            };

            //console.log(params);

            this.$store.dispatch('statisticsStore/clickDaily', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    var dataArray = res.data;
                    dataArray = dataArray.reverse();
                    //console.log(dataArray);

                    var currentBucket = "";
                    var labelArray = [];
                    var imageArray = [];
                    var videoArray = [];
                    var videoplayArray = [];

                    var imageCnt = 0;
                    var videoCnt = 0;
                    var videoplayCnt = 0;

                    var totalImageClick = 0;
                    var totalVideoClick = 0;
                    var totalVideoPlay = 0;

                    for(var i = 0; i < dataArray.length; i++){

                        if(currentBucket == ""){
                            
                            var bucketArray = dataArray[i].bucket.split("T");
                            currentBucket = dataArray[i].bucket;
                            labelArray.push(bucketArray[0]);

                        }else{

                            if(currentBucket != dataArray[i].bucket){

                                imageArray.push(imageCnt);
                                videoArray.push(videoCnt);
                                videoplayArray.push(videoplayCnt);

                                imageCnt = 0;
                                videoCnt = 0;
                                videoplayCnt = 0;

                                bucketArray = dataArray[i].bucket.split("T");
                                currentBucket = dataArray[i].bucket;
                                labelArray.push(bucketArray[0]);

                            }

                        }

                        if(dataArray[i].adtype == "image"){
                            imageCnt += dataArray[i].countnum;
                            totalImageClick += dataArray[i].countnum;
                        }else if(dataArray[i].adtype == "video"){
                            videoCnt += dataArray[i].countnum;
                            totalVideoClick += dataArray[i].countnum;
                        }else if(dataArray[i].adtype == "videoplay"){
                            videoplayCnt += dataArray[i].countnum;
                            totalVideoPlay += dataArray[i].countnum;
                        }

                        if(i == dataArray.length - 1){
                            imageArray.push(imageCnt);
                            videoArray.push(videoCnt);
                            videoplayArray.push(videoplayCnt);
                        }
                        
                    }

                    this.clickStats.image = totalImageClick;
                    this.clickStats.video = totalVideoClick;
                    this.clickStats.videoplay = totalVideoPlay;

                    // for문 종료
                    this.adtypeArray = {
                        // Data for the y-axis of the chart
                        labels: labelArray,

                        datasets: [
                            {
                                label: '배너/이미지 클릭수',
                                backgroundColor: '#e1a4a4',
                                data: imageArray
                            },
                            {
                                label: '동영상 클릭수',
                                backgroundColor: '#a4e1be',
                                data: videoArray,
                            },
                            {
                                label: '동영상 플레이수',
                                backgroundColor: '#a4bae1',
                                data: videoplayArray,
                            }
                        ]
                    }
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 문의하기 발송 내역
        getContacts(){

            var params = {
                siteid: this.siteid,
                sdate: this.sdate,
                edate: this.edate,
            };

            this.$store.dispatch('statisticsStore/getContacts', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    
                    var mainContact = 0;
                    var boothContact = 0;
                    for(var i = 0; i < res.data.length; i++){
                        if(res.data[i].boothid == ""){
                            mainContact++;
                        }else{
                            boothContact++;
                        }
                    }

                    this.contactStats.mainContact = mainContact;
                    this.contactStats.boothContact = boothContact;
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 문의하기 일별 총계
        getContactsDaily(){

            var params = {
                siteid: this.siteid,
                sdate: this.sdate,
                edate: this.edate,
            };

            this.$store.dispatch('statisticsStore/contactsDaily', params)
            .then((res) => {
                //console.log(res);
                if(res.success){
                    
                    var dataArray = res.data;
                    dataArray = dataArray.reverse();
                    //console.log(dataArray);

                    var currentBucket = "";
                    var newData = [];
                    var contactCount = 0;
                    var label = "";

                    for(var i = 0; i < dataArray.length; i++){

                        if(currentBucket == ""){
                            
                            var bucketArray = dataArray[i].bucket.split("T");
                            currentBucket = dataArray[i].bucket;
                            label = bucketArray[0];
                            contactCount += dataArray[i].contactCount;

                        }else{

                            if(currentBucket != dataArray[i].bucket){

                                var item = {
                                    label : label,
                                    value : contactCount
                                }
                                newData.push(item);

                                currentBucket = dataArray[i].bucket;
                                bucketArray = dataArray[i].bucket.split("T");
                                label = bucketArray[0];
                                contactCount = dataArray[i].contactCount;

                            }else{
                                contactCount += dataArray[i].contactCount;
                            }
                        }

                        if(i == dataArray.length - 1){
                            item = {
                                label : label,
                                value : contactCount
                            }
                            newData.push(item);
                        }
                    }

                    this.drawGraph(newData, 'contact');
                }
            })
            .catch(({message}) => (alert(message)));
        },

        // 마토모 통계 가져오기
        getMatomoInfo(){

            var thisEl = this;
            var sdate = "";
            if(new Date(this.sdate) < new Date("2023-09-03")){
                sdate = "2023-09-03";
            }else{
                sdate = this.sdate;
            }

            var matomoUrl = "https://analytics.onmice.net/?module=API&method=UserCountry.getCity&idSite="+this.matomoid+"&period=range&date="+sdate+","+this.edate+"&format=JSON&filter_limit=10&token_auth=a56ba9d1bfaa83b823f74e23f475ce2c";
            $.getJSON(matomoUrl, function(data){
                //console.log(data);
                var newData = [];
                for(var i = 0; i < data.length; i++){
                    var item = {
                        label : data[i].city_name,
                        value : data[i].nb_visits
                    }
                    newData.push(item);
                }
                thisEl.drawGraph(newData, 'country');
            });
            
            var matomoUrl2 = "https://analytics.onmice.net/?module=API&method=DevicesDetection.getType&idSite="+this.matomoid+"&period=range&date="+sdate+","+this.edate+"&format=JSON&filter_limit=10&token_auth=a56ba9d1bfaa83b823f74e23f475ce2c";
            $.getJSON(matomoUrl2, function(data){
                //console.log(data);
                var newData = [];
                for(var i = 0; i < data.length; i++){
                    if(data[i].nb_visits > 0){
                        var item = {
                            label : data[i].label,
                            value : data[i].nb_visits
                        }
                        newData.push(item);
                    }
                }
                thisEl.drawGraph(newData, 'device');
            });

            var matomoUrl3 = "https://analytics.onmice.net/?module=API&method=DevicesDetection.getOsVersions&idSite="+this.matomoid+"&period=range&date="+sdate+","+this.edate+"&format=JSON&filter_limit=10&token_auth=a56ba9d1bfaa83b823f74e23f475ce2c";
            $.getJSON(matomoUrl3, function(data){
                //console.log(data);
                var newData = [];
                for(var i = 0; i < data.length; i++){
                    var item = {
                        label : data[i].label,
                        value : data[i].nb_visits
                    }
                    newData.push(item);
                }
                thisEl.drawGraph(newData, 'os');
            });

            var matomoUrl4 = "https://analytics.onmice.net/?module=API&method=VisitsSummary.get&idSite="+this.matomoid+"&period=day&date="+sdate+","+this.edate+"&format=JSON&token_auth=a56ba9d1bfaa83b823f74e23f475ce2c";
            $.getJSON(matomoUrl4, function(data){
                //console.log(data);
                var labelArray = Object.keys(data);
                var newData = [];

                var totalVisits = 0;
                var uniqueVisits = 0;

                for(var i = 0; i < labelArray.length; i++){
                    var nb_visits = 0;
                    if(data[labelArray[i]].nb_visits == undefined){
                        nb_visits = 0;
                    }else{
                        nb_visits = data[labelArray[i]].nb_visits;
                        totalVisits += data[labelArray[i]].nb_visits;
                        uniqueVisits += data[labelArray[i]].nb_uniq_visitors;
                    }
                    var item = {
                        label : labelArray[i],
                        value : nb_visits
                    }
                    newData.push(item);
                }

                thisEl.visitorStats.totalVisitor = totalVisits;
                thisEl.visitorStats.uniqueVisitor = uniqueVisits;
                
                thisEl.drawGraph(newData, 'daily');
            });
        },

        // 그래프 그리기
        drawGraph(data, mode){
            var labels = [];
            var values = [];
            for(var i = 0; i < data.length; i++){
                labels.push(data[i].label);
                values.push(data[i].value);
            }

            if(mode == 'country'){
                this.matomoCountry = {
                    // Data for the y-axis of the chart
                    labels: labels,

                    datasets: [
                        {
                            label: '방문수',
                            backgroundColor: '#a98dbe',
                            // Data for the x-axis of the chart
                            data: values
                        }
                    ]
                }
            }else if(mode == 'device'){
                this.matomoDeviceType = {
                    // Data for the y-axis of the chart
                    labels: labels,

                    datasets: [
                        {
                            label: '방문수',
                            backgroundColor: ['#a98dbe', '#8fcfc4', '#acd59c', '#e3b2a5', '#a0bcd8', '#ebb978' ],
                            fill: false,
                            //borderColor: '#9775af',
                            borderJoinStyle: 'miter',
                            // Data for the x-axis of the chart

                            data: values
                        }
                    ]
                }
            }else if(mode == 'os'){
                this.matomoDeviceOs = {
                    // Data for the y-axis of the chart
                    labels: labels,

                    datasets: [
                        {
                            label: '방문수',
                            backgroundColor: '#a98dbe',
                            // Data for the x-axis of the chart
                            data: values
                        }
                    ]
                }
            }else if(mode == 'daily'){
                this.matomoDailyVisits = {
                    // Data for the y-axis of the chart
                    labels: labels,

                    datasets: [
                        {
                            label: '방문수',
                            backgroundColor: '#a98dbe',
                            // Data for the x-axis of the chart
                            data: values
                        }
                    ]
                }
            }else if(mode == 'contact'){
                this.contactArray = {
                    // Data for the y-axis of the chart
                    labels: labels,

                    datasets: [
                        {
                            label: '문의수',
                            backgroundColor: '#a98dbe',
                            // Data for the x-axis of the chart
                            data: values
                        }
                    ]
                }
            }
        },

        async exportToPDF () {

            this.$refs.topStatRow.style.display = "block";
            this.$refs.topStatRowLeft.style.width = "100%";
            this.$refs.pdfarea.style.padding = "20px 0px";
            this.$refs.topBox.style.margin = "0px 0px 15px 0px";
            this.$refs.top2title1.style.margin = "0px 0px 16px 0px";
            this.$refs.top2title2.style.margin = "0px 0px 16px 0px";
            this.$refs.top2title3.style.margin = "0px 0px 16px 0px";

            await html2pdf(this.$refs.pdfarea, {
                margin: [0,5,0,5],
                filename: '대시보드.pdf',
                pagebreak: { mode: 'avoid-all' },
                image: {type:"jpg", quality: 1},
                //	allowTaint 옵션추가
                html2canvas: {
                    useCORS: true,
                    scrollY: 0,
                    scale:1,
                    dpi: 300,
                    letterRendering: true,
                    allowTaint: false //useCORS를 true로 설정 시 반드시 allowTaint를 false처리 해주어야함
                },
                jsPDF: {orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true}
            })

            this.$refs.topStatRow.style.display = "flex";
            this.$refs.topStatRowLeft.style.width = "66.66667%";
            this.$refs.pdfarea.style.padding = "30px 0px";
            this.$refs.topBox.style.margin = "0px 0px 20px 0px";
            this.$refs.top2title1.style.margin = "0px 0px 1.5rem 0px";
            this.$refs.top2title2.style.margin = "0px 0px 1.5rem 0px";
            this.$refs.top2title3.style.margin = "0px 0px 1.5rem 0px";
        },

        // 엑셀 다운로드
        excelDownload(type){

            var apiName = "";
            var fileName = "";
            var headers = [];
            var datas = [];
            var labels = [];

            if(type == "dailyclick"){
                apiName = "clickDailyExcel";
                fileName = "부스 일별 클릭 통계 리스트.xlsx";
            }else if(type == "dailycontact"){
                apiName = "contactsDailyExcel";
                fileName = "일별 문의 통계 리스트.xlsx";
            }else if(type == "contact"){
                apiName = "contactsExcel";
                fileName = "문의내역 리스트.xlsx";
            }else if(type == "dailybooth"){
                apiName = "boothDailyExcel";
                fileName = "부스 방문 통계 리스트.xlsx";
            }else{
                if(type == "matomoDailyVisits"){
                    fileName = "일별 방문자 수 리스트.xlsx";
                    headers = [
                        "날짜",
                        "방문수"
                    ];
                    labels = this.matomoDailyVisits.labels;
                    datas = this.matomoDailyVisits.datasets[0].data;
                }else if(type == "matomoDeviceType"){
                    fileName = "디바이스 타입별 방문 통계 리스트.xlsx";
                    headers = [
                        "디바이스 타입",
                        "방문수"
                    ];
                    labels = this.matomoDeviceType.labels;
                    datas = this.matomoDeviceType.datasets[0].data;
                }else if(type == "matomoCountry"){
                    fileName = "지역별 방문 통계 리스트.xlsx";
                    headers = [
                        "지역",
                        "방문수"
                    ];
                    labels = this.matomoCountry.labels;
                    datas = this.matomoCountry.datasets[0].data;
                }else if(type == "matomoDeviceOs"){
                    fileName = "운영체제별 방문 통계 리스트.xlsx";
                    headers = [
                        "운영체제",
                        "방문수"
                    ];
                    labels = this.matomoDeviceOs.labels;
                    datas = this.matomoDeviceOs.datasets[0].data;
                }
                apiName = "matomoExcel";
            }

            var params = {
                siteid: this.siteid,
                sdate: this.sdate,
                edate: this.edate,
            };

            if(type.includes("matomo")){
                params = {
                    filename: fileName,
                    headers: headers,
                    labels: labels,
                    datas: datas,
                }
            }

            //console.log(params);

            this.$store.dispatch('statisticsStore/' + apiName, params)
            .then((res) => {
                //console.log(res);
                if(res){
                    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);

                    // 링크 생성 및 클릭하여 다운로드
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName; // 파일명 지정
                    a.click();

                    // 메모리 누수 방지를 위한 정리
                    window.URL.revokeObjectURL(url);
                }
            });
        },
    },
    
};
</script>
