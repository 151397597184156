<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img src="@/assets/img/logo.png">
        </router-link>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link to="/" class="navbar-item">
            ONMICE {{ username }} 통계
          </router-link>

          <span class="bar">|</span>
   
          <router-link to="/contact" class="navbar-item">문의 게시판</router-link>
          
        </div>



        

        <div  class="navbar-end">
         
          <div class="navbar-item">
            <div class="buttons" v-if="isLogin">
              <a href="javascript:;" @click="logout();" class="button loginbtn">
                로그아웃
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import { supabase } from '../../lib/supabaseClient'
export default {
    data() {
      return {
        isLogin: false,
        username: "",
      };
    },
    watch: {
        $route(to, from){
            var userInfo = this.$store.getters['user'];
            if(userInfo.data != null){
                this.getUserInfo(userInfo.data.userUqId);
                this.isLogin = true;
            }else{
                this.isLogin = false;
                this.username = "";
            }
        }
    },
    mounted(){
        var userInfo = this.$store.getters['user'];
        //console.log(userInfo);
        if(userInfo.data != null){
            this.getUserInfo(userInfo.data.userUqId);
            this.isLogin = true;
        }
    },
    methods: {

        // 유저 이름 가녀오기
        async getUserInfo(userUqId){
            let { data, error } = await supabase.from('users').select().eq('id', userUqId)

            //console.log(data);
            this.username = data[0].company;
        },

        // 로그아웃
        async logout() {
            this.$store.commit('logoutUser');
            let { error } = await supabase.auth.signOut()
            this.$router.push("/login");
        },
    }
};
</script>